<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar></NavSidebar>
        </div>
        <div class="main col-11 h-100 py-3 padding-subpages">
            <NavHeader tutorialName="none" buttonLabel="Zur Projektübersicht" chevron="true" chevronLeft="true" :link="true"
                linkTo="/uebersicht"></NavHeader>

            <HeaderCard info="false" :icons="[]" info_color='red'>
                <template #title>
                    Über das Tool
                </template>
                <template #description>
                    Auf dieser Seite finden Sie Hintergrundinformationen zur Funktionsweise des Tools.
                </template>
            </HeaderCard>

            <section id="faq-tool" style="margin-top: 20px">
                <div class="faq">
                    <Collapse :id=1>
                        <template #title>
                            Auf welcher Basis werden die Inhalte des Tools ausgewählt?
                        </template>
                        <template #text>
                            <p><b>Werte</b></p>
                            <p> Ausgangsbasis für die Inhalte der Übung „Wertereflexion“ war eine Stakeholder Befragung, die
                                wir im Zeitraum von November 2022 bis März 2023 mit elf Personen, die im Gesundheitswesen
                                tätig sind,
                                durchgeführt haben. Wir haben versucht, eine möglichst große Bandbreite hinsichtlich der
                                unterschiedlichen Tätigkeiten und Aspekte in unserem Gesundheitssystem abzudecken: Hospiz
                                und Sterbebegleitung,
                                (Alten-)Pflege sowie die Unterstützung von Klientinnen und Klienten mit Behinderungen,
                                psychologische Betreuungsangebote, aber auch die medizinische Grundversorgung bis hin zum
                                Rettungswesen.
                                Eine Stakeholder Befragung wird auch in der Value Sensitive Design Methode empfohlen. Sie
                                dient ganz allgemein dem Perspektivwechsel und einem kontextsensiblen Denken. Genau das
                                wollen wir mit der Übung
                                „Wertereflexion“ erreichen. Wir haben mit den Personen in unserer Befragung über die für sie
                                wichtigsten Werte im Gesundheitssystem gesprochen und beziehen die meisten unserer Beispiele
                                für das Modul
                                „Wertereflexion“ aus diesen Gesprächen. Die Auswahl der dreizehn Werte wurde ebenfalls auf
                                dieser Basis getroffen, denn es waren die am häufigsten genannten Werte.
                                Werte haben für verschiedene Menschen und in unterschiedlichen Kontexten nicht immer die
                                gleiche Bedeutung. Für Forschungsprojekte ist es wichtig, ein gemeinsames Werteverständnis
                                zu entwickeln.
                                Wir bieten deshalb in diesem Übungsteil keine akademischen Definitionen von ethischen
                                Werten, sondern ausschließlich Anregungen zur Bedeutungsvielfalt, die auch widersprüchlich
                                sein kann,
                                um die Nutzerinnen und Nutzer von ELSI SAT Health & Care zum eigenständigen Nachdenken zu
                                inspirieren. Wir möchten zeigen, welche Bedeutungen diese Werte in bestimmten Kontexten
                                haben können,
                                ohne eine eindeutige Bedeutung vorgeben zu wollen. Die kurzen Texte sind ausschließlich als
                                Denkanstöße zu verstehen. Die Übung „Wertereflexion“ bietet dadurch die Voraussetzungen,
                                um in den Teams in einen Austausch und Dialog zu gelangen, um über ihre eigenen
                                Wertevorstellungen und darüber hinaus zu diskutieren.</p>
                            <p><b>Fragebogen</b></p>
                            <p>Mit dieser klareren Übersicht möglicher Implikationen kann nun geprüft werden, welche
                                Technologieeigenschaften es denn üblicherweise sind, die Konflikte oder Herausforderungen
                                in den entsprechenden Kategorien auslösen. Hier spielen Aspekte wie Neuartigkeit,
                                Datenverarbeitung, Prozesstransparenz, Nutzungskompetenzanforderungen, Material- und
                                Ressourcenverbrauch,
                                Einzel-/Massenproduktion, oder Anschaffungs- und Nutzungskosten eine Rolle. Diese sollten
                                bei Vorliegen eines mehr oder weniger klar umrissenen Anwendungsszenarios der anvisierten
                                Technologie
                                von den Entwickler*innen auch ohne ELSI-Vorwissen angegeben werden können. Demnach ist die
                                Erfassung solcher Eigenschaften das wesentliche Kernelement eines Assessments, das
                                ELS-Implikationen einschätzen soll.
                                Dies ist nicht nur in digitalen Tools der Fall, sondern auch in der herkömmlichen
                                ELSI-Begleitforschung, in der Technikfolgenforscher in kommunikativem Austausch mit
                                Forschungs- und Entwicklungsteams zu verstehen versuchen,
                                wie eine neuartige Entwicklung in der Welt wirken wird. In Analogie zu diesen Konsultationen
                                werden die Innovatoren also auch in diesem Tool weder direkt nach einer Einschätzung von
                                ELS-Implikationen noch nach einer
                                kontextuellen Bewertung ethischer und rechtlicher Prinzipien oder Werte gefragt, sondern
                                nach möglichst detaillierten Spezifikationen bezüglich derjenigen Technologieparameter, die
                                Aufschluss über die ELSI-Zusammenhänge geben können.
                            </p>
                            <p><b>ELSI-Kategorien</b></p>
                            <p>Ausgangspunkt der Strukturierung von ELS-Aspekten im Tool ist eine Analyse der üblichen, in
                                der wissenschaftlichen Literatur gut dokumentierten, technologieinduzierten Konfliktfelder
                                und Veränderungspotenziale.
                                Hier fällt zunächst auf, dass sich ethische Diskussionen auf zwei Ebenen fokussieren, die
                                systemische und die individuell-lokale Dimension. Da dies, wie später zu sehen, sehr
                                unterschiedliche praktische Auswirkungen für die Arbeit der
                                Technikentwickler hat, ist es sinnvoll, diese beiden Gruppen von Kategorien auch im Tool zu
                                unterscheiden. Die systemische Oberkategorie umfasst all diejenigen Einfluss- und
                                Veränderungspotentiale, die sich auf systemische Wirkungsgefüge,
                                Entscheidungsketten und Zustände beziehen. Die leitende Frage ist, wie die Platzierung eines
                                innovativen technischen Produkts bestehende Systeme (inklusive der in den Systemen
                                etablierten Regeln und Normen) zu Veränderungen (aktive Anpassungen,
                                passive Reaktionen, Nebenfolgen) herausfordert. Hier sind dies die politische Dimension
                                (Gesundheitspolitik, Regulierung), die ökonomische Dimension (Gesundheitsökonomie,
                                Finanzierung des Gesundheitssystems), der Bereich Arbeitsmarkt und
                                Berufsbilder im Gesundheits-, Medizin- und Pflegekontext, die ökologische Dimension
                                (Umwelteinflüsse, Nachhaltigkeit), sowie die gesellschaftliche Dimension. Die
                                individuell-lokale Oberkategorie beschreibt die Implikationen, die auf individueller,
                                persönlicher, interpersönlicher, im H&C-Kontext direkt sich vollziehender Ebene entstehen.
                                Gemeint sind Herausforderungen mit ethischer oder rechtlicher Dimension, die durch die
                                Anwendung einer innovativen Technologie im häuslichen,
                                ambulanten oder stationären Umfeld für Patienten, medizinisches Fachpersonal, Pflegekräfte
                                oder Servicemitarbeiter entstehen.</p>
                        </template>
                    </Collapse>

                    <Collapse :id=2>
                        <template #title>
                            Wozu dient das Wertesystem?
                        </template>
                        <template #text>
                            Das Tool bietet die Option einer visuell unterstützten Reflexion von Werten, die gestalterisch
                            so im Tool angezeigt wird, dass sie sinnvollerweise vor dem Projektfragebogen durchgeführt wird.
                            Hier können die Projektteammitglieder
                            allein oder im Team aus einer Liste von Werten, die durch Texteingabe auch selbst erweitert
                            werden kann, diejenigen Werte auswählen, die sie als wichtig oder relevant für das Projekt
                            erachten. Die Werte werden dazu aus der Liste in
                            ein Radardiagramm so gezogen und platziert, dass in der Mitte ein zentraler Wert und drumherum
                            die weiterhin relevanten Werte stehen. Mithilfe der Begleittexte im Tool wird sichergestellt,
                            dass diese Übung die Beantwortung der Fragen
                            im Kernfragebogen vorbereitet, indem die Toolnutzenden sich in ein Anwendungsszenario ihrer
                            Technologie hineindenken und gedanklich nicht im Entwicklungsprozess selbst verhaftet bleiben.
                        </template>
                    </Collapse>
                    <Collapse :id=3>
                        <template #title>
                            Wie entsteht das ELSI-Profil?
                        </template>
                        <template #text>
                            Die Fragen werden so gestellt, dass aus den Antworten Punkte in die ELSI-Kategorien verteilt
                            werden können, um schließlich ein ELSI-Profil zu erstellen, das in Form eines Balkendiagrams die
                            Relevanz der jeweiligen Kategorie für
                            das vorliegende Technologieprojekt anzeigt. Ein hoher Wert, bzw. langer Balken impliziert, dass
                            die Technologie Eigenschaften aufweist, die ethisch, rechtlich oder gesellschaftlich relevante
                            Einflüsse in dieser Kategorie wahrscheinlich machen.
                            Ein geringerer Wert, bzw. kürzerer Balken weist auf eine weniger stark tangierte Kategorie hin.
                            Das Profil zeigt also keine negativ assoziierte Risiko- bzw. Gefahreneinschätzung, sondern
                            bietet eine projektspezifische Orientierung möglicher
                            Brennpunkte und Handlungsbedarfe. Der Vorteil dieser Sortierung ist, dass Forschende sich im
                            nächsten Schritt gezielt zu den offensichtlich relevanten Themen informieren können, um
                            schließlich Methoden der integrierten Forschung auswählen können,
                            die im intendierten Projekt sinnvoll und fruchtbar implementiert und umgesetzt werden können.
                        </template>
                    </Collapse>

                    <Collapse :id=4>
                        <template #title>
                            Wie werden die ELSI-Karten genutzt?
                        </template>
                        <template #text>
                            Nachdem die Beantwortung des Kernfragebogens im projektspezifischen ELSI-Profil resultiert,
                            können die Toolnutzenden entscheiden, mit welchen der offensichtlich für sie relevanten
                            Kategorien sie sich näher beschäftigen wollen.
                            Aus dem Profil gelangen sie per Klick auf eine der Kategorien zu der entsprechenden ELSI-Karte.
                            Diese enthält Kurz- und Langbeschreibungen der Kategorie, Problemszenarien, Fallbeispiele und
                            Übersichten von für die Kategorie
                            relevanten ethischen Werten und Rechtsthemen. Alle wichtigen ethischen und rechtlichen
                            Stichwörter werden in einem Glossar genauer erklärt. Kernelement der ELSI-Karten sind die
                            Fallbeispiele, die so strukturiert sind, dass
                            die Relevanz für die Technikentwicklung stets verständlich gemacht wird. Mit dem Ziel, dass
                            Technologieentwickler*innen erkennen, mit welchen Methoden sie die in den Kategorien
                            beschriebenen Implikationen adressieren können,
                            wird anhand der Fallbeispiele erläutert, wie einerseits bestimmte Designentscheidungen und
                            Forschungsrichtungen zu den angesprochenen Konflikten oder Problem führen, und welche Methoden
                            die Entwickler*innen andererseits anwenden
                            können (bzw. in realen Fallbeispielen, welche tatsächlich angewandt wurden), um
                            Entwicklungsrichtungen zu beeinflussen und dabei Werte zu schützen, bzw. Rechte und Gesetze zu
                            achten. Ohne dass dabei konkrete Empfehlungen und
                            Ratschläge ausgesprochen werden (was das Tool auch gar nicht leisten darf), werden Konzepte der
                            integrierten und partizipativen Forschung und Entwicklung wie value-sensitive design, design
                            thinking, open innovation, ethical
                            vision assessment oder co-design vorgestellt, oder es wird auf Möglichkeiten der
                            interdisziplinären Kooperation mit Experten und möglichen zusätzlichen Forschungspartnern
                            hingewiesen.

                        </template>
                    </Collapse>
                    <Collapse :id=5>
                        <template #title>
                            Wozu dient die Projektreflexion?
                        </template>
                        <template #text>
                            In das Tool wurde eine Anleitung zur projektbezogenen Gruppenreflexion integriert, die
                            sinnvollerweise an den Fragebogen und die Beschäftigung mit den ELSI-Karten anschließt, um die
                            dort gewonnenen Erkenntnisse
                            in die Projektbeschreibung einfließen zu lassen. Mit geeigneten Leitfragen und
                            Reflexionstechniken werden die Forschenden in der eigenen Transferleistung unterstützt, damit
                            das intendierte ethics-by-design, also
                            die Zusammenführung technologischer und normativer Expertise, zum Erfolg kommt.

                        </template>
                    </Collapse>

                    <Collapse :id=6>
                        <template #title>
                            Wie wurde das Tool evaluiert?
                        </template>
                        <template #text>
                            Das Tool wurde in einem iterativen Prozess mit Nutzenden evaluiert und auf dieser Basis
                            weiterentwickelt. Insgesamt fanden sechs Studien mit Nutzenden über den gesamten Zeitraum des
                            Projekts statt.
                            Dabei wurden die Testpersonen zu ihrem Kontext interviewt, durften die jeweils aktuelle Version
                            der Anwendung ausprobieren und alle ihre Eindrücke schildern. Anfangs diente ein Prototyp zur
                            Evaluation,
                            am Ende geschah dies mit der realen Anwendung. Das gesammelte Feedback sowie neue Erkenntnisse
                            dienten nach jeder Studie als Basis für die Optimierung des Tools.


                        </template>
                    </Collapse>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import NavHeader from "../components/Nav_Header.vue"
import HeaderCard from "../components/Header_Card.vue"
import SortSidebar from "../components/Sort_Sidebar.vue"
import NavSidebar from "../components/Nav_Sidebar.vue"
import Collapse from "../components/Collapse.vue"

export default {
    name: 'glossar',
    components: {
        NavHeader,
        HeaderCard,
        SortSidebar,
        NavSidebar,
        Collapse
    },
    computed: {
        terms() {
            return this.$store.getters['survey/getTermsByGlossarFilter']
        },
    },
    data() {
        return {
            info: "Auf dieser Seite finden Sie Hintergrundinformationen zur Funktionsweise des Tools.",
        }
    }
}

</script>

<style lang="scss" scoped>
section#faq-tool .faq {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
}
</style>
